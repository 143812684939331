<template>
  <div>
    <div class="text-center pt-3">
      <h1 class="font-weight-bolder text-white mb-6">Support Center</h1>
      <div class="h4 text-white-50">Deep Dive into our Knowledgebase</div>
    </div>
    <div class="card card-custom p-6 mb-8 mb-lg-4">
      <div class="card-body d-flex align-items-center justify-content-between">
        <div class="mr-4">
          <h3 class="text-dark mb-6">
            Did not find an answer? Send us a note!
          </h3>
          <p class="text-dark-50 font-size-lg">
            You can submit a ticket to our support system. <br />You must be
            logged in to the game you own to open a support ticket.
          </p>
        </div>
        <a
          href="https://support.beyazgames.com/newticket.php"
          class="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
          target="_blank"
          >Submit a Request</a
        >
      </div>
    </div>

    <div
      class="accordion accordion-solid accordion-panel accordion-svg-toggle mb-10"
      id="faq"
    >
      <b-card class="card p-6">
        <b-card-header class="card-header" role="button">
          <b-button
            class="card-title font-size-h4 text-dark m-1"
            href="#"
            v-b-toggle.accordion-1
            ><div class="card-label" style="text-align:left;">
              I want support for the game ?
            </div>
            <span class="svg-icon svg-icon-primary"
              ><inline-svg
                src="media/svg/icons/Navigation/supportTik.svg"/></span
          ></b-button>
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="button"
        >
          <b-card-body
            class="card-body pt-3 font-size-h6 font-weight-normal text-dark-50"
          >
            <b-card-text
              >For this, you can click on the settings of the game you are
              playing and create a ticket to our support system from the support
              tab or you can send an e-mail to support@beyazgames.com describing
              your support request.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card class="card p-6">
        <b-card-header class="card-header" role="button">
          <b-button
            class="card-title font-size-h4 text-dark m-1"
            href="#"
            v-b-toggle.accordion-2
            ><div class="card-label" style="text-align:left;">
              I'm sending you an e-mail but no response ?
            </div>
            <span class="svg-icon svg-icon-primary"
              ><inline-svg
                src="media/svg/icons/Navigation/supportTik.svg"/></span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="button">
          <b-card-body
            class="card-body pt-3 font-size-h6 font-weight-normal text-dark-50"
          >
            <b-card-text
              >If you send us too many e-mails, you will probably end up in the
              spam box and we will not see your e-mails. For this, it is
              sufficient to send an e-mail to our e-mail addresses explaining
              your reason for contact in detail.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card class="card p-6">
        <b-card-header class="card-header" role="button">
          <b-button
            class="card-title font-size-h4 text-dark m-1"
            href="#"
            v-b-toggle.accordion-3
            ><div class="card-label" style="text-align:left;">
              I can't access my game account, what should I do in this case?
            </div>
            <span class="svg-icon svg-icon-primary"
              ><inline-svg
                src="media/svg/icons/Navigation/supportTik.svg"/></span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="button">
          <b-card-body
            class="card-body pt-3 font-size-h6 font-weight-normal text-dark-50"
          >
            <b-card-text
              >If you cannot access your game account and open a ticket, you can
              create a detailed support request at
              support@beyazgames.com.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card class="card p-6">
        <b-card-header class="card-header" role="button">
          <b-button
            class="card-title font-size-h4 text-dark m-1"
            href="#"
            v-b-toggle.accordion-4
            ><div class="card-label" style="text-align:left;">
              What does the status page do?
            </div>
            <span class="svg-icon svg-icon-primary"
              ><inline-svg
                src="media/svg/icons/Navigation/supportTik.svg"/></span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="button">
          <b-card-body
            class="card-body pt-3 font-size-h6 font-weight-normal text-dark-50"
          >
            <b-card-text
              >From this page, you can see all our server network status and
              check if there is a maintenance or technical glitch.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "support",
  components: {},
  computed: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Support Center" }]);
  }
};
</script>
